























































































































































































import { reactive, toRefs, ref, computed, onMounted } from '@vue/composition-api';
import { useUserActions } from '@/store';
import { copyText } from 'vue3-clipboard';
import { valid } from 'overlayscrollbars';
import { program } from '@babel/types';

export default {
  name: 'ModulePresets',
  props: {
    value: {
      required: true,
      type: Object
    },
    userType: {
      required: false,
      type: String,
      default: ''
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    adkIndex: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props: any, ctx) {
    const setup = reactive({});
    const checkboxChecked = ref(false);
    const { updateAdkData } = useUserActions(['updateAdkData']);
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    onMounted(() => {
      ctx.emit('is-checked', checkboxChecked.value);
    });
    const adkIndexValue: any = computed({
      get: () => props.adkIndex,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const currentAdk: any = computed(() => {
      return programDoc.value?.data?.adks?.[adkIndexValue.value];
    });

    const fields: any = computed(() => {
      if (programDoc.value?.fields) {
        return programDoc.value?.fields;
      }
      if (currentAdk.value?.setupRequired === true) {
        return currentAdk?.value.fields;
      }
      return currentAdk.value?.presetFields;
    });

    const field = computed(() => {
      if (fields?.value?.length) {
        return fields.value.find(item => item.name === 'm-welcome-adk');
      }
      return false;
    });

    const styling = computed(() => {
      if (field) {
        return field?.value?.styling || programDoc?.value?.styling;
      }
      return false;
    });

    const btnLabel = computed(() => {
      return styling?.value?.btnLabel || 'Verify & Save Link';
    });

    const textHint = computed(() => {
      return styling?.value?.hint || 'Please make sure it is editable by anyone who views';
    });

    const inputLabel = computed(() => {
      return styling?.value?.label || 'Enter link to shared drive (ie: Google Drive)';
    });

    const headerLabel = computed(() => {
      return styling?.value?.headerLabel || 'Insert get started folder (Recommended)';
    });

    const checkboxReadonly = ref(false);
    const index = adkIndexValue.value;
    let fieldIndex;
    if (props.userType === 'employer' && currentAdk.value?.setupRequired === false) {
      fieldIndex = programDoc?.value?.data?.adks[index]?.presetFields?.findIndex(field => {
        if (field.name) {
          return field.name === 'm-welcome-adk';
        }
        return false;
      });
    } else {
      fieldIndex = programDoc?.value?.data?.adks?.[index]?.fields?.findIndex(field => {
        if (field.name) {
          return field?.name === 'm-welcome-adk';
        }
        return false;
      });
    }
    const mVideoAskfieldIndex = programDoc?.value?.data?.adks?.[index]?.fields?.findIndex(field => {
      if (field.name) {
        return field.name === 'm-videoask';
      }
      return false;
    });

    if (programDoc.value?.data?.adks[index]?.completed) {
      checkboxChecked.value = true;
    }

    const driveLink = ref();
    if (
      props.userType === 'employer' &&
      programDoc.value?.data?.adks?.[index]?.presetFields[fieldIndex]?.setup?.driveLink
    ) {
      driveLink.value =
        programDoc.value?.data?.adks?.[index]?.presetFields?.[fieldIndex]?.setup?.driveLink;
    } else {
      driveLink.value =
        programDoc.value?.data?.adks?.[index]?.fields?.[fieldIndex]?.value?.driveLink;
    }
    const isVerifyLink = ref(false);
    async function verifyLink() {
      isVerifyLink.value = true;
      if (currentAdk.value?.setupRequired === true) {
        field.value.value.driveLink = driveLink.value;
      } else {
        field.value.setup.driveLink = driveLink.value;
      }
      await updateAdkData({
        program_id: programDoc.value.data._id.toString(),
        document_type: 'Program',
        data: programDoc.value?.data?.adks[index]
      });
      setTimeout(() => {
        isVerifyLink.value = false;
      }, 2000);
    }
    const copied = ref(false);
    const doCopy = () => {
      copyText(driveLink.value, undefined, error => {
        if (error) {
          console.log(error);
        } else {
          copied.value = true;
          setTimeout(() => {
            copied.value = false;
          }, 5000);
        }
      });
    };
    function openGetStartedFolder() {
      let link;
      if (driveLink.value.includes('https://')) {
        link = driveLink.value;
      } else {
        link = `https://${driveLink.value}`;
      }
      window.open(link, '_blank');
    }
    return {
      fields,
      inputLabel,
      headerLabel,
      textHint,
      btnLabel,
      programDoc,
      ...toRefs(setup),
      index,
      driveLink,
      process,
      verifyLink,
      isVerifyLink,
      doCopy,
      copied,
      fieldIndex,
      checkboxChecked,
      checkboxReadonly,
      mVideoAskfieldIndex,
      openGetStartedFolder
    };
  }
};
