var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0"},[(_vm.userType === 'employer')?_c('div',{staticStyle:{"width":"100%"}},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex flex-column pa-12"},[_c('div',{staticClass:"d-flex justify-center mb-8"},[_c('h3',[_vm._v(" "+_vm._s(_vm.headerLabel)+" ")])]),_c('div',[_c('validation-provider',{attrs:{"rules":{
              regex: /^((?:https?:)?\/\/)?((?:www)\.)?((?:(.*)\.com))(.*)/,
              required: true
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"justify-center align-center mb-4",attrs:{"disabled":_vm.disabled,"error-messages":errors,"label":_vm.inputLabel,"x-large":"","rounded":"","outlined":"","type":"url","hint":_vm.textHint},model:{value:(_vm.driveLink),callback:function ($$v) {_vm.driveLink=$$v},expression:"driveLink"}},[(!_vm.copied)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""},on:{"click":_vm.doCopy}},on),[(!invalid)?_c('v-icon',[_vm._v("mdi-content-copy")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Copy content")])]):_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""}},on),[(!invalid)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Link copied")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"welcome__full-width-btn font-weight-bold",attrs:{"disabled":invalid,"rounded":"","dark":!invalid,"color":"yellow","x-large":"","depressed":"","loading":_vm.isVerifyLink},on:{"click":function($event){return _vm.verifyLink()}}},[_vm._v(_vm._s(_vm.btnLabel))])],1)])]}}],null,false,3517229318)})],1):_c('div',[_c('div',{staticClass:"pa-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',[(
              _vm.programDoc.data.adks &&
              !_vm.programDoc.data.adks[_vm.index].fields[_vm.mVideoAskfieldIndex].value
                .signInVideoAskResponse
            )?_c('iframe',{attrs:{"width":"100%","height":"380px","src":"https://www.youtube.com/embed/HnEdGFhizHg","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_c('iframe',{staticStyle:{"border":"none","border-radius":"0px"},attrs:{"src":_vm.programDoc.data.adks[_vm.index].fields[_vm.mVideoAskfieldIndex].value
                .signInVideoAskResponse &&
              _vm.programDoc.data.adks[_vm.index].fields[_vm.mVideoAskfieldIndex].value.signInVideoAskResponse
                .contact &&
              _vm.programDoc.data.adks[_vm.index].fields[_vm.mVideoAskfieldIndex].value.signInVideoAskResponse
                .contact.share_url,"allow":"autoplay *; encrypted-media *; fullscreen *;","width":"100%","height":"600px"}})]),(_vm.driveLink)?_c('div',{staticClass:"d-flex flex-column pa-12"},[_vm._m(0),_c('div',[_c('v-text-field',{staticClass:"justify-center align-center mb-4",attrs:{"label":"Copy & open get started folder","x-large":"","rounded":"","outlined":"","hide-details":"","value":_vm.driveLink,"readonly":""}},[(!_vm.copied)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""},on:{"click":_vm.doCopy}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,2410691547)},[_c('span',[_vm._v("Copy content")])]):_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1)]}}],null,false,3648222325)},[_c('span',[_vm._v("Link copied")])])],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"welcome__full-width-btn font-weight-bold",attrs:{"rounded":"","dark":"","color":"yellow","x-large":"","depressed":""},on:{"click":_vm.openGetStartedFolder}},[_vm._v("Open Get Started Folder")])],1)]):_vm._e(),_c('div',{staticClass:"ml-12 mr-12 d-flex flex-column"},[(_vm.driveLink)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{attrs:{"readonly":_vm.checkboxReadonly || !!_vm.programDoc.data.adks[_vm.index].completed,"label":"I have reviewed the welcome video & get started folder"},on:{"click":function($event){return _vm.$emit('is-checked', _vm.checkboxChecked)}},model:{value:(_vm.checkboxChecked),callback:function ($$v) {_vm.checkboxChecked=$$v},expression:"checkboxChecked"}})],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{attrs:{"readonly":_vm.checkboxReadonly || !!_vm.programDoc.data.adks[_vm.index].completed,"label":"I have reviewed the welcome video"},on:{"click":function($event){return _vm.$emit('is-checked', _vm.checkboxChecked)}},model:{value:(_vm.checkboxChecked),callback:function ($$v) {_vm.checkboxChecked=$$v},expression:"checkboxChecked"}})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center mb-4"},[_c('h3',[_vm._v("Get Started")])])}]

export { render, staticRenderFns }